.eaglei-report {
    display: block;
    padding: 0 10px;
}

.report-header {
    font-size: 24px;
    margin-bottom: 5px;
}

.grid {
    padding-top: 20px;
}

img.table-calendar {
    height: 10px;
}

.report-filters.main-row {
    & > button:last-child {
        margin-left: auto;
    }

    & > div:last-child {
        margin-left: auto;
    }
}

mat-header-row {
    margin-top: 10px;
    background-color: #074569;
}

mat-header-cell {
    color: white !important;
    font-size: 18px !important;
    font-weight: normal !important;
}

mat-header-row .mdi-sort {
    padding-left: 5px;
}

mat-row:hover {
    background-color: #eee;
}

.report-filters {
    padding-bottom: 10px;

    .second-row {
        padding-bottom: 10px;
    }
}

.report-chart {
    height: 300px;
    width: 100%;
    opacity: 1;
    margin-top: 10px;

    &.hide-chart {
        opacity: 0;
        pointer-events: none;
    }
}

.mask {
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    position: absolute;
    top: 0;

    .mask-text {
        min-width: 200px;
        text-align: center;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateY(-50%) translateX(-50%);
        background-color: white;
        padding: 15px;
        border-radius: 5px;
    }
}

.table-attribution {
    text-align: right;
    font-size: 12px;
}

.report-loading {
    margin-top: initial;
    background-color: grey;
}

.auto-mask {
    height: 100%;
    width: 100%;
    margin: auto;
    padding: 40px;
    text-align: center;
    z-index: 10;
    display: flex;
    flex-direction: column;

    &.hide-mask {
        position: absolute;
        top: 0;
        left: 0;
        border-radius: 5px;
        background-color: rgba(45, 45, 45, 0.2);
    }

    & .mask-message {
        border: 1.5px solid #a6a6a6;
        border-radius: 5px;
        padding: 15px 35px;
        margin-top: 2rem;
        background-color: rgba(112, 112, 112, 0.15);
        color: #7b7b7b;
        min-width: 200px;
        width: 290px;
        text-align: center;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateY(-50%) translateX(-50%);
    }
}

mat-header-cell div.mat-sort-header-arrow {
    display: none;
}

//Material Table
/*New Material Table*/

div.table-container {
    width: 100%;
    overflow: none;
    overflow-x: auto;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    z-index: 0;
}

table.report-table {
    &.mat-table {
        width: 100%;
    }

    & th {
        margin-top: 10px;
        background-color: #074569;
        color: white !important;
        font-size: 16px !important;
        white-space: nowrap;

        & div.cell-icons {
            padding-left: 5px;
        }
    }

    & tr {
        color: white !important;
        font-size: 16px !important;
        font-weight: normal !important;
        white-space: nowrap;
    }

    & th .mdi-sort {
        padding-left: 5px;
    }

    & th .mat-sort-header-arrow {
        color: white;
    }

    & td,
    th {
        padding-right: 25px;
    }

    & td > .ellipsis {
        display: inline-block;
        width: 95%;
    }

    .right-align {
        text-align: right;
    }

    .center {
        text-align: center;
    }
}

.table-mask {
    background-color: white;
    margin: auto;
    padding: 20px;
}

.chart-target {
    height: 300px;
}

img.date-hover {
    height: 10px;

    &.large {
        height: 20px;
    }
}

.filter-bar {
    display: flex;
    gap: 1rem;
    align-items: flex-start;

    eaglei-date-range {
        min-width: min-content;
    }

    .button-group {
        margin-top: 5px;

        button {
            mat-icon {
                width: 1rem;
                height: 1rem;
                margin: 0;
                padding: 0;
            }
        }
    }
}
